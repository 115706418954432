var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BackBtn',{attrs:{"click_handler":_vm.go_to_company_user_staff},scopedSlots:_vm._u([{key:"btn-text",fn:function(){return [_vm._v(" К списку сотрудников ")]},proxy:true}])}),_c('PageTitle',{on:{"edit_btn_clicked":_vm.edit_btn_clicked},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.fio)+" ")]},proxy:true},{key:"title-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary darken-3"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.current_staff.is_active)?_c('ActiceUserIcon',_vm._g({class:[_vm.$style.icon, 'mr-2']},on)):_c('DeactivateUserIcon',_vm._g({class:[_vm.$style.icon, 'mr-2']},on))]}}])},[_vm._v(" "+_vm._s(_vm.current_staff.is_active ? 'Активна' : 'Неактивна')+" ")])]},proxy:true},{key:"edit-btn-text",fn:function(){return [_vm._v(" Редактировать учетную запись ")]},proxy:true}])}),_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"8"}},[_c('VerticalTable',{attrs:{"cols":_vm.main_info_cols,"items_info":_vm.current_staff},scopedSlots:_vm._u([{key:"text-item-image",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-caption pt-3 pb-3"},[_c('b',[_vm._v(" "+_vm._s(item.text)+" ")]),_c('br'),_c('span',[_vm._v(" (Помогает персонализировать вашу учетную запись) ")])])]}},{key:"field-item-image",fn:function(){return [_c('td',{staticClass:"pt-3 pb-3"},[_c('Avatar',{attrs:{"original_initials":_vm.original_initials,"avatar":_vm.avatar}})],1)]},proxy:true},{key:"field-item-roles",fn:function(){return [_c('td',[_vm._v(" "+_vm._s(_vm.roles)+" ")])]},proxy:true},{key:"field-item-created_at",fn:function(){return [_c('td',[_vm._v(" "+_vm._s(_vm._f("date")(_vm.current_staff_field('created_at')))+" ")])]},proxy:true},{key:"field-item-birth_date",fn:function(){return [_c('td',[_vm._v(" "+_vm._s(_vm._f("date")(_vm.current_staff_field('birth_date')))+" ")])]},proxy:true},{key:"field-item-verification_date",fn:function(){return [_c('td',[_vm._v(" "+_vm._s(_vm._f("date")(_vm.current_staff_field('verification_date')))+" ")])]},proxy:true},{key:"text-item-actions",fn:function(){return [_c('td',[_c('v-tooltip',{attrs:{"max-width":"300px","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._v(" Управление доступом "),_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" Управление доступом ")])])],1)]},proxy:true},{key:"field-item-actions",fn:function(){return [_c('td',{staticClass:"pt-4 pb-4"},[(_vm.current_staff.is_active)?_c('div',{class:_vm.$style.btns},[_c('v-btn',{attrs:{"color":"#000","icon":"","title":"Заблокировтаь"},on:{"click":function($event){return _vm.handle_activation(_vm.current_staff, false)}}},[_c('v-icon',[_vm._v("mdi-account-off-outline")])],1),_c('span',[_vm._v("Заблокировать учетную запись")])],1):_c('div',{class:_vm.$style.btns},[_c('v-btn',{attrs:{"color":"#000","icon":"","title":"Активировать"},on:{"click":function($event){return _vm.handle_activation(_vm.current_staff, true)}}},[_c('v-icon',[_vm._v("mdi-account-check-outline")])],1),_c('span',[_vm._v("Активировать учетную запись")])],1)])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('VerticalTable',{attrs:{"cols":_vm.contacts_cols,"items_info":_vm.current_staff,"single_cell_render":""},scopedSlots:_vm._u([{key:"table-title",fn:function(){return [_vm._v(" Контакты ")]},proxy:true},_vm._l((_vm.contacts_cols),function(col,i){return {key:_vm.slot_name(col.field),fn:function(ref){
var item = ref.item;
return [_c('td',{key:i},[_vm._v(" "+_vm._s(_vm.current_staff[item.field])+" "),_c('br'),_c('small',{domProps:{"innerHTML":_vm._s(item.text)}})])]}}})],null,true)})],1)],1),_c('SimpleDialog',{attrs:{"max-width":"564","center_title":"","bts_align":"center"},on:{"close":function($event){_vm.activation_modal = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.alert_model.title)+" ")]},proxy:true},{key:"text",fn:function(){return [_c('div',[(!_vm.alert_model.is_active)?_c('div',[_vm._v(" "+_vm._s(_vm.alert_model.text)+" "),_c('span',{class:_vm.$style.itemTitleText},[_vm._v(_vm._s(_vm.current_staff.full_name)+"?")])]):_c('div',[_vm._v(" "+_vm._s(_vm.alert_model.text)+" ")])])]},proxy:true},{key:"actions",fn:function(){return [_c('td',{staticClass:"py-2"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"accent darken-4","depressed":""},on:{"click":_vm.toggle_activation}},[_vm._v(" "+_vm._s(_vm.alert_model.btn_proceed)+" ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.activation_modal = false}}},[_vm._v(" "+_vm._s(_vm.alert_model.btn_cancel)+" ")])],1)]},proxy:true}]),model:{value:(_vm.activation_modal),callback:function ($$v) {_vm.activation_modal=$$v},expression:"activation_modal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }